<!-- src/components/AdminLayout.vue -->
<template>
    <div class="flex h-screen">
      <NavBar :sidebarOpen="sidebarOpen" @toggleSidebar="toggleSidebar" />
  
      <!-- Overlay for mobile view -->
      <div v-if="sidebarOpen" @click="toggleSidebar" class="fixed inset-0 bg-black opacity-50 z-10 lg:hidden"></div>
  
      <!-- Main Content -->
      <div class="flex-1 flex flex-col lg:ml-1">
        <!-- Header -->
        <header class="bg-white shadow flex justify-between items-center p-4">
          <button class="lg:hidden" @click="toggleSidebar">
            <svg class="w-6 h-6 text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
          <h1 class="text-3xl font-bold text-gray-900">
            <slot name="title"></slot>
          </h1>
          <div class="flex items-center space-x-4">
            <img src="https://via.placeholder.com/40" alt="Profile Picture" class="w-10 h-10 rounded-full">
            <span class="text-gray-800">User Name</span>
          </div>
        </header>
  
        <!-- Main -->
        <main class="flex-1 bg-gray-100 p-6">
          <slot></slot>
        </main>
      </div>
    </div>
  </template>
  
  <script>
  import NavBar from './NavBar.vue';
  
  export default {
    components: {
      NavBar,
    },
    data() {
      return {
        sidebarOpen: false,
      };
    },
    methods: {
      toggleSidebar() {
        this.sidebarOpen = !this.sidebarOpen;
      },
    },
  };
  </script>
  
  
<!-- src/components/Users.vue -->
<template>
    <AdminLayout>
      <template #title>Users</template>
      <div class="max-w-7xl mx-auto">
        <p class="text-gray-700">Users content goes here.</p>
      </div>
    </AdminLayout>
  </template>
  
  <script>
  import AdminLayout from './AdminLayout.vue';
  
  export default {
    components: {
      AdminLayout
    }
  };
  </script>
  
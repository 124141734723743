<!-- src/views/AdminUsersView.vue -->
<template>
  <AdminUsers />
</template>

<script>
import AdminUsers from '../components/AdminUsers.vue';

export default {
  components: {
    AdminUsers
  }
};
</script>

  
<!-- src/components/AdminDashboard.vue -->
<template>
  <AdminLayout>
    <template #title>Dashboard</template>
    <div class="max-w-7xl mx-auto">
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <!-- Card 1 -->
        <div class="bg-white p-6 rounded-lg shadow">
          <h2 class="text-xl font-semibold">Card Title 1</h2>
          <p class="mt-2 text-gray-600">Card content goes here.</p>
        </div>
        <!-- Card 2 -->
        <div class="bg-white p-6 rounded-lg shadow">
          <h2 class="text-xl font-semibold">Card Title 2</h2>
          <p class="mt-2 text-gray-600">Card content goes here.</p>
        </div>
        <!-- Card 3 -->
        <div class="bg-white p-6 rounded-lg shadow">
          <h2 class="text-xl font-semibold">Card Title 3</h2>
          <p class="mt-2 text-gray-600">Card content goes here.</p>
        </div>
      </div>
    </div>
  </AdminLayout>
</template>

<script>
import AdminLayout from './AdminLayout.vue';

export default {
  components: {
    AdminLayout
  }
};
</script>

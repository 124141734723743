<!-- src/views/AdminSettingsView.vue -->
<template>
  <AdminSettings />
</template>

<script>
import AdminSettings from '../components/AdminSettings.vue';

export default {
  components: {
    AdminSettings
  }
};
</script>

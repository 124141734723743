// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import AdminDashboardView from '../views/AdminDashboardView.vue';
import AdminSettingsView from '../views/AdminSettingsView.vue';
import AdminUsersView from '../views/AdminUsersView.vue';

const routes = [
  { path: '/', redirect: '/dashboard' },
  { path: '/dashboard', component: AdminDashboardView },
  { path: '/settings', component: AdminSettingsView },
  { path: '/users', component: AdminUsersView },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;


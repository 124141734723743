<!-- src/components/AdminSettings.vue -->
<template>
  <AdminLayout>
    <template #title>Settings</template>
    <div class="max-w-7xl mx-auto">
      <p class="text-gray-700">Settings content goes here.</p>
    </div>
  </AdminLayout>
</template>

<script>
import AdminLayout from './AdminLayout.vue';

export default {
  components: {
    AdminLayout
  }
};
</script>

<!-- src/views/AdminDashboardView.vue -->
<template>
  <AdminDashboard />
</template>

<script>
import AdminDashboard from '../components/AdminDashboard.vue';

export default {
  components: {
    AdminDashboard
  }
};
</script>

  
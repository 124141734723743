<!-- src/components/NavBar.vue -->
<template>
    <div :class="['bg-gray-800 text-gray-100 w-64 fixed lg:relative transition-transform transform lg:translate-x-0', sidebarOpen ? 'translate-x-0' : '-translate-x-full']">
      <div class="p-4 text-center text-xl font-semibold border-b border-gray-700">
        Admin Panel
      </div>
      <nav class="mt-4">
        <router-link to="/dashboard" class="block py-2.5 px-4 hover:bg-gray-700">Dashboard</router-link>
        <router-link to="/users" class="block py-2.5 px-4 hover:bg-gray-700">Users</router-link>
        <router-link to="/settings" class="block py-2.5 px-4 hover:bg-gray-700">Settings</router-link>
      </nav>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      sidebarOpen: {
        type: Boolean,
        required: true,
      }
    }
  };
  </script>
  